const _base = "/api";
export const ACCESS_TOKEN_API = _base + "/api_user/access_token";
export const LOGIN_API = _base + "/students/login/";
export const USER_DETAIL_API = _base + "/students/user_details";
export const UPDATE_USER_DETAIL_API = _base + "/students/edit_profile";
export const FETCH_CAROUSEL_API = _base + "/students/fetch_carousel";
export const FETCH_EVENTS_API = _base + "/students/fetch_user_events";
export const ENROLL_EVENT_API = _base + "/students/enroll_event";
export const JOIN_EVENT_API = _base + "/students/user_join_event";
export const GET_INDIVIDUAL_EVENT_API = _base + "/students/event_details";
export const SCHOOL_SKILLS_LIST_API = _base + "/teachers/school_skills";

export const FTECH_SKILLS_BY_TEACHER_API =
  _base + "/teachers/fetch_skills_by_teacher";
export const FETCH_TECHER_JOURNEY_API = _base + "/students/fetch_ilms_by_skill";
export const FETCH_SKILL_PROGRAMS_API =
  _base + "/teachers/fetch_skill_ilms_teacher";
export const FETCH_CLASSROOM_DETAIL_API =
  _base + "/students/fetch_class_details";
export const FETCH_DAILYQUEST_API = _base + "/teachers/fetch_daily_quests";
export const UPDATE_WATCH_HISTORY_API =
  _base + "/teachers/teacher_watch_history";
export const UPDATE_SCORM_PROGRESS_API =
  _base + "/students/update_scorm_progress";
export const TICKET_LIST_API = _base + "/students/fetch_technical_issues";
export const CREATE_TICKET_API = _base + "/students/track_technical_issues";
export const FAQ_LIST_API = _base + "/students/fetch_user_faqs";
export const FETCH_TUTORIAL_LIST_API = _base + "/students/tutorial";

export const FAVOURITE_LIST_API = _base + "/students/get_favorites_by_user_id";
export const ADD_TO_FAVOURITE_API = _base + "/students/add_favorites";
export const REMOVE_FROM_FAVOURITE_API = _base + "/students/remove_favorites";
export const WATCH_HISTORY_LIST_API =
  _base + "/students/get_watch_profile_history";

export const DISPLAY_PROJECTS_API = _base + "/teachers/display_projects";
export const CONFIRM_PROJECT_SELECTION_API =
  _base + "/students/multi_project_marks_allocation";

// dashboard
export const GET_GRADE_SECTION_DATA_API =
  _base + "/dashboard_report/get_grade_section";
export const GET_LEARNING_PROGRESS_API =
  _base + "/dashboard_report/get_learning_progress";
export const POPULAR_ILMS_API = _base + "/dashboard_report/popular_ilms";
export const SKILL_COMPLETION_API =
  _base + "/dashboard_report/skill_completion";
export const GET_LOGIN_STATUS_DETAIL_API =
  _base + "/dashboard_report/get_login_status_details";
// 1 for top 3 & 2 for everithing
export const TOP_PERFOMERS_API = _base + "/dashboard_report/top_performers";
export const GET_LOGIN_STATUS_API =
  _base + "/dashboard_report/get_login_status";
export const GET_USER_STATS_API = _base + "/dashboard_report/get_user_status";
export const STUDENTS_REPORT_SKILLWISE_API =
  _base + "/dashboard_report/student_report_skillwise";
export const STUDENTS_REPORT_DETAIL_API =
  _base + "/dashboard_report/student_report_details";
export const PERFORMANCE_TREND_API =
  _base + "/dashboard_report/performance_trends";
export const USER_TREND_API = _base + "/dashboard_report/user_trends";
export const EVENT_DETAILS_API = _base + "/dashboard_report/event_details";
export const USER_TYPE_SUMMARY_API =
  _base + "/dashboard_report/user_type_summary";
export const SCHOOL_LOGIN_STATUS_API =
  _base + "/dashboard_report/school_login_status";
export const SCHOOL_TOP_PERFOMERS_API =
  _base + "/dashboard_report/top_performers_school";
export const SCHOOL_DASHBOARD_USER_STATS_API =
  _base + "/dashboard_report/school_user_stats";
export const SCHOOL_OPTED_SKIILS_API =
  _base + "/dashboard_report/school_opted_skills";
export const SCHOOL_STUDENT_ENROLLED_SKIILS_API =
  _base + "/dashboard_report/school_student_enrolled_skills";
export const SCHOOL_USAGE_SUMMARY_API =
  _base + "/dashboard_report/usage_summary";

// fetch cors- config
export function apiRequestOptions(body) {
  const token =
    window.localStorage.getItem("accessToken") ??
    "f582adc593fe72fd6ff9aa85d446395c";
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      access_token: token,
    },
    body: JSON.stringify(body),
  };
  return options;
}
